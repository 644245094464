import "./imgGalleryItem.css";

/**
 * @brief Renders one image gallery item 
 * 
 * Info related to images
 * @param props.id Identificator of the image gallery item 
 * @param props.imgSource image source of the image gallery item 
 * @param props.imgTitle={element.title}
 *
 * Flags
 * @param props.isImgShown Determines if the image gallery item is shown 
 * 
 * Pointers to callback functions
 * @param props.onLoad handles OnLoad image event
 * @param props.onClick handles OnClick image event
 * @returns 
 */
function ImgGalleryItem(props) {
  return (
    <div className="pics" key={props.id}>
      <img
        width={props.isImgShown ? "100%" : "0%"}
        src={props.imgSource}
        onClick={props.onClick}
        onLoad={props.onLoad}
        alt=""
      />
      <div className="overlay"
        onClick={props.onClick}>
        <p>{props.imgTitle}</p>
      </div>
    </div>
  );
}
export default ImgGalleryItem;
