import { useEffect, useState } from "react";
import FamilyMembers from "../components/FamilyMemberCard/familyMembers";
import WaitingScreen from "../components/PhotoGallery/WaitingScreen";
import "./guestBook.css";

/**
 * @brief Page that shows our guest book. Showrt overview of our family and our friends
 */
function GuestBook() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedFamilyMembers, setLoadedFamilyMembers] = useState([]); // 2D array
  const [familyBranches, setFamilyBranches] = useState([]);

  // State variable used to check if all images are already loaded.
  const [isGallery1Loaded, setIsGallery1Loaded] = useState(false);
  const [isGallery2Loaded, setIsGallery2Loaded] = useState(false);

  // Fetch Json data only once
  useEffect(() => {
    fetch(
      "https://wedding-dd47b-default-rtdb.europe-west1.firebasedatabase.app/familyMembers.json"
    )
      .then((response) => {
        // The json(..) function return Promise, so we can return this function
        return response.json();

      })
      .then((data) => {
        const arrFamilyMembers = []; // 2D array
        const arrFamilyBranches = [];
        for (const familyBranch in data) {
          let arrFamilyMembersId = data[familyBranch].map(a => a.id)
          arrFamilyBranches.push(familyBranch)
          for (const id in arrFamilyMembersId) {
            arrFamilyMembers.push([familyBranch, data[familyBranch][id]])
          }
        }

        setIsLoading(false);
        setLoadedFamilyMembers(arrFamilyMembers);
        setFamilyBranches(arrFamilyBranches);
      });
  }, [isLoading]); // <- add empty brackets here to run this section only when rendered for the first time.

  // Render gallery when our data are already loaded
  if (!isLoading) {

    // First family tree - Kaja
    // Get only elements with index[0] === familyBranches[0] - Kája
    var familyBranch1 = loadedFamilyMembers.filter(function (value, index) { return value[0] === familyBranches[0]; });
    // Keep only the second column (index 1)
    familyBranch1 = familyBranch1.map(function (value, index) { return value[1]; })

    // Second family branch - Ondra
    // Get only elements with index[0] === familyBranches[1] - Ondra
    var familyBranch2 = loadedFamilyMembers.filter(function (value, index) { return value[0] === familyBranches[1]; });
    // Keep only the second column (index 1)
    familyBranch2 = familyBranch2.map(function (value, index) { return value[1]; })

    var isGaleryLoaded = isGallery1Loaded && isGallery2Loaded;

    return (
      <table className="guestBook_container">
        <WaitingScreen isShown={!isGaleryLoaded} />

        <div className="guestBook_leftBox">
        <h1>Tým nevěsta</h1>
          <FamilyMembers
            items={familyBranch1}
            setGaleryLoaded={setIsGallery1Loaded} />
        </div>
        <div className="guestBook_rightBox">
        <h1>Tým ženich</h1>
          <FamilyMembers
            items={familyBranch2}
            setGaleryLoaded={setIsGallery2Loaded} />
        </div>
      </table>
    );
  }
}
export default GuestBook;