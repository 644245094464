import { useEffect } from "react";
import GoogleMap from "./GoogleMap";
import "./infoCard.css"
import SpotifyPlaylist from "./SpotifyPlaylist";



function InfoCard(props) {

    let IsMapSrcDefined = !((props.mapSrc === undefined) || (props.mapSrc.length === 0));
    let IsIconSrc2Defined = !((props.iconSrc2 === undefined) || (props.iconSrc2.length === 0));
    let IsImageDefined = !((props.imgSrc === undefined) || (props.imgSrc.length === 0));

    let IsSpotifySrcDefined = !((props.spotifySrc === undefined) || (props.spotifySrc.length === 0));

    useEffect(() => {
        // In case no source was defined, we say it is loaded.
        if (!IsMapSrcDefined && !IsSpotifySrcDefined) {
            props.onLoad()
        }
        // The line bellow shush the warning
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // run only on the first render

    function GetMap() {
        if (!IsMapSrcDefined) {
            return <></>;
        }
        return <GoogleMap src={props.mapSrc} onLoad={props.onLoad} width={"80%"} height={"200px"}></GoogleMap>;
    }

    
    function GetImage() {
        if (!IsImageDefined) {
            return <></>;
        }
        return <img src={props.imgSrc} onLoad={props.onLoad} className="QrCode" alt="Qr code" width={"30%"} height={"auto"}></img>;
    }

    function GetIcon2() {
        if (!IsIconSrc2Defined) {
            return <></>;
        }
        return <div className="icon2">
            <img className="icon2"
                src={props.iconSrc2}
                alt=""
            />
        </div>;
    }

    function GetSpotifyPlaylist() {

        if (!IsSpotifySrcDefined) {
            return <></>;
        }
        return <SpotifyPlaylist src={props.spotifySrc} onLoad={props.onLoad} width={"80%"} height={"400px"}></SpotifyPlaylist>;
    }


    return (
        <div className="infoCard_div">
            <div className="infoCard_container">
                <div className="infoCard_leftBox">
                    <div className={props.isIconOnLeftSide ? "icon" : "icon hidden"}>
                        <img
                            src={props.iconSrc}
                            alt=""
                        />
                        {props.iconText}
                    </div>
                </div>

                <div className="infoCard_middleBox">
                    {GetIcon2()}
                    <h2>{props.msgTitle}</h2>
                    <p>{props.msgText}</p>
                </div>

                <div className="infoCard_rightBox">
                    <div className={props.isIconOnLeftSide ? "icon hidden" : "icon"}>
                        <img
                            src={props.iconSrc}
                            alt=""
                        />
                        {props.iconText}
                    </div>
                </div>
                <div className="map">
                    {GetMap()}
                    {GetSpotifyPlaylist()}
                    {GetImage()}
                </div>
            </div>
        </div>

    );
}

export default InfoCard;