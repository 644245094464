import "./familyMemberCard.css"



function FamilyMemberCard(props) {

    // Check length of images
    var len = Object.keys(props.previewSource).length

    var images_HTML = <></>
    switch (len) {
        case 1:
            images_HTML = <img
                width={props.isImgShown ? "100%" : "0%"}
                src={props.previewSource[0]}
                onClick={props.onClick.bind(null, props, 0)}
                onLoad={props.onLoad}
                alt=""
            />
            break;
        case 2:
            images_HTML = <>
                <img
                    width={props.isImgShown ? "100%" : "0%"}
                    src={props.previewSource[0]}
                    onClick={props.onClick.bind(null, props, 0)}
                    onLoad={props.onLoad}
                    alt=""
                />
                <img
                    width={props.isImgShown ? "100%" : "0%"}
                    src={props.previewSource
                    [1]}
                    onClick={props.onClick.bind(null, props, 1)}
                    onLoad={props.onLoad}
                    alt=""
                />
            </>
            break;

        default:
            break;
    }

    return (
        <div className="familyMemberCard_div">
            <div className="familyMemberCard_container">
                <h2>{props.familyMemberName}</h2>
                <div className="photo">
                    {images_HTML}
                    {props.iconText}
                </div>
                <p>{props.msgText}</p>
            </div>
        </div>

    );
}

export default FamilyMemberCard;