import { useEffect, useState } from "react";
import DateTimeCountdown from "../components/DateTimeCountdown";
import InfoCard from "../components/InfoCard/InfoCard";

import "./home.css";
import qr_gift from '../res/qr_gift.jpg' // relative path to image 

function Home() {

  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoadedCountDown, setIsLoadedCountDown] = useState(false)
  const [loadedInfoCardsCount, setLoadedInfoCardsCount] = useState(0)

  // Count of used info Cards
  const COUNT_OF_CARDS = 6;

  /**
   * @brief Set the page loaded when we load:
   *        - All info cards determined by COUNT_OF_CARDS
   *        - CountDown of remaining time to the wedding
   */
  useEffect(() => {
    // tmp
    if (isLoadedCountDown) {
      setIsLoaded(true)
    }
    // Problematic on server
    if (loadedInfoCardsCount >= COUNT_OF_CARDS &&
      isLoadedCountDown) {
      setIsLoaded(true)
    }
  }, [loadedInfoCardsCount, isLoadedCountDown])


  function onLoadedInfoCard() {
    // Increment loadedImgCount by 1
    setLoadedInfoCardsCount((prevValue) => prevValue + 1);
  }

  return (
    <div className={isLoaded ? "home_div" : "home_div hidden"}>
      <DateTimeCountdown date='Aug 12, 2023 14:00' onLoad={setIsLoadedCountDown}> </DateTimeCountdown>

      <h3>
        Vítejte na svatebním webu Bouráků.
      </h3>
      <p>
        Najdete zde všechny důležité informace týkající našeho svatebního dne. Pokud Vám však nebude něco jasné nebo se chcete jen v něčem ujistit, neváhejte nás kontaktovat.
      </p>
      <br></br>
      <div className="home_navList">
        <InfoCard
          onLoad={onLoadedInfoCard}
          mapSrc='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d171861.5169516515!2d15.2225496455416!3d49.49080775474284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470ce37f4307f811%3A0xfd30f2e463158e3c!2zU2Ftb3RhIGphayB2w61ubyDwn42HIFVieXRvdsOhbsOtIGEgc3ZhdGJ5Lg!5e0!3m2!1scs!2scz!4v1668255424604!5m2!1scs!2scz'
          iconSrc='https://drive.google.com/uc?export=view&id=1bJJIJ6zAlFx2b66ggcf-2QbRssQRm4-k'
          iconSrc2='https://drive.google.com/uc?export=view&id=17RhXJIYV7PtHuSQjA4FHac8dJajWD3BB'
          isIconOnLeftSide={true}
          iconText=''
          msgTitle='Samota jak víno'
          msgText='Budeme se na Vás moc těšit v sobotu 12. srpna 2023 od 14 h. Ideálně doražte s cca 1h předstihem (tedy okolo 13:00). Místo se nachází v obci Dudín kousek od Humpolce (D1 exit 90). Na místě je dostatek parkovacích míst.'
        />

        <InfoCard
          onLoad={onLoadedInfoCard}
          mapSrc=''
          iconSrc='https://drive.google.com/uc?export=view&id=1ziUtcjkOlZbNOwk75FYGYL4t3CpO2M2G'
          isIconOnLeftSide={true}
          iconText=''

          msgTitle='Možnost přespání'
          msgText='Na statku je kapacita míst k přespání pod střechou bohužel velmi malá i tak ale doufáme, že s námi vydržíte až do rána. Ubytování ve vlastních stanech bude možné ve stanovém městečku, které společně postavíme v průběhu odpoledne.'
        />

        <InfoCard
          onLoad={onLoadedInfoCard}
          mapSrc=''
          iconSrc='https://drive.google.com/uc?export=view&id=13tXaIdB8JW1f3NUurDhTOXs_o0WwOLHl'
          isIconOnLeftSide={true}
          iconText=''

          msgTitle='Jaký bude program?'
          msgText='Program se zatím stále vymýšlí. Hned až budem něco vědět, informujeme Vás.'
        />

        <InfoCard
          onLoad={onLoadedInfoCard}
          mapSrc=''
          iconSrc='https://drive.google.com/uc?export=view&id=1tPKD_6ORSNsytxGKvdisSlu90FoOWbKU'
          isIconOnLeftSide={true}
          iconText=''

          msgTitle='Co na sebe ?'
          msgText='Obřad i následný program budou probíhat na travnaté zahradě statku. Vezměte si proto něco pohodlného pro celodenní radovánky.'
        />

        <InfoCard
          onLoad={onLoadedInfoCard}
          mapSrc=''
          iconSrc='https://drive.google.com/uc?export=view&id=1Cn4-hFDHJN1vvBQ30y6L4mTlkomIXwAz'
          imgSrc={qr_gift}
          isIconOnLeftSide={true}
          iconText=''
          msgTitle='Náš nevětší dárek bude vaše účast'
          msgText='Největší radost nám uděláte tím, že dorazíte a pořádně si to s námi užijete. Pokud byste nám chtěli zanechat drobný příspěvek na naše další dobrodružství, budeme moc rádi. Jestli jako my žijete v bezhotovostním světě můžete využít číslo našeho účtu: 1268318030/3030 nebo případně níže uvedený QR kód 🙂.'
        />

        <InfoCard
          onLoad={onLoadedInfoCard}
          mapSrc=''
          spotifySrc='https://open.spotify.com/embed/playlist/4Xps1xf5lbuemLLFVnq4S3?si=C00ldI9PQIybLKujB2FPOw&pt=2138a5cec4659c3dc92cfca9ad52ba17'
          iconSrc='https://drive.google.com/uc?export=view&id=1oiTPJRMKwRb6c70TVOoW8NQAHmGYhXg_'
          isIconOnLeftSide={true}
          iconText=''
          msgTitle='Písničky přímo na míru'
          msgText='Doufáme, že s námi protančíte střevíce. Aby nám to šlo ještě lépe, zveme Vás do našeho svatebního playlistu (Spotify) kam můžete přidat Vaše oblíbené songy.'
        />
      </div>
    </div>
  );
}
export default Home;