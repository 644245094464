import React from 'react'
import "./googleMaps.css";

/**
 * @brief Renders Google map iframe
 *
 * Parameters
 * @param props.src source that leads to desired map
 * @param props.width Map width
 * @param props.height Map height
 */
function GoogleMap(props) {
  return (
    <iframe src={props.src}
      title="Title"
      className="googleMap"
      width={props.width}
      height={props.height}
      frameBorder="0"
      allowFullScreen=""
      aria-hidden="false"
      loading="lazy"
      onLoad={props.onLoad}
      tabIndex="0">
    </iframe>
  )
}

export default GoogleMap;