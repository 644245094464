import MainNavigation from "./MainNavigation";
import "./Layout.css";
import { useState } from "react";

function Layout(props) {
  const [isNavbarLoaded, setNavbarLoaded] = useState(false);
  return (
    <div>
      <MainNavigation setNavbarLoaded={setNavbarLoaded} />
      <main>
        <div className={isNavbarLoaded ? "main-container loaded" : "main-container"}>
          {props.children}
        </div>
      </main>
    </div>
  );
}

export default Layout;
