import React, { useState, useEffect } from "react";
import ImgGalleryItem from "./ImgGalleryItem";
import ImgFullScreen from "./ImgFullScreen";
import Masonry from "react-masonry-css";
import WaitingScreen from "./WaitingScreen";

import "./gallery.css";

/**
 * @brief Component for rendereing image gallery.
 * @note  This component uses the Masonry component leveraging CSS .
 * @param props.items Json objects that contain pieces of info about images to be rendered.
 *
 */
function Gallery(props) {
  // State variable used to check if all images are already loaded.
  const [isGalleryLoaded, setIsGalleryLoaded] = useState(false);

  // State variable that holds value of actually loaded images.
  // Note: Used as a trigger "useEffect()" hook.  that is triggered when loadedImgCount changes.
  const [loadedImgCount, setLoadedImgCount] = useState(0);

  // State variable used to set full screen mode
  const [isImgFS, setIsImgFS] = useState(false);

  // State variable showing if we already loaded all images (loadedImgCount is equal to count of loaded images)
  const [isImgFS_Loaded, setIsImgFS_Loaded] = useState(false);

  // State variable that holds Json object of currently viewed image in full screen
  const [objectToViewFS, setObjectToViewFS] = useState({});

  /**
   * @brief Check if we already loaded all images.
   * @note  Re-run only if the loadedImgCount changes
   */
  useEffect(() => {
    // Show gallery when all images are loaded
    if (loadedImgCount >= props.items.length - 1) {
      setIsGalleryLoaded(true);
    }
  
  }, [loadedImgCount, props.items.length]); // Dependency "props.items.length" is here only tu shush warning.


 
  useEffect(() => {
    const detectKeyDown = (e) => {
      console.log(e.key)
      /*
      if (e.key === 'ArrowLeft')    // Left arrow
      {
        onPrevBtn()
      }    
      else if (e.key === 'ArrowRight')    // Right arrow
      {
        onNextBtn()
      }*/

      if (e.key === 'Escape')    // Escape
      {
        onCloseBtn()
      }
    }
    document.addEventListener('keydown', detectKeyDown, true)

  }, []); 

  
  /**
   * @brief Change state variables to view image in full screen.
   * @param item Json object that contains info about the image to view.
   */
  function viewImg_FS(item) {
    setObjectToViewFS(item);
    setIsImgFS(true);
  }

  /**
   * @brief View next image in full screen
   * @param currentID ID of the Json object that contains the currently viewed image source.
   */
  function ViewNextImg_FS(currentID) {
    props.items.forEach((item, index) => {
      if (currentID === item.id) {
        if (index < props.items.length) {
          viewImg_FS(props.items[index + 1]);
        }
      }
    });
  }

  /**
   * @brief View previous image in full screen
   * @param currentID ID of the Json object that contains the currently viewed image source.
   */
  function ViewPrevImg_FS(currentID) {
    props.items.forEach((item, index) => {
      if (currentID === item.id) {
        if (index > 0) {
          viewImg_FS(props.items[index - 1]);
        }
      }
    });
  }

  /**
   * @brief Should be called when a single image is loaded. We increase our conter of loaded images
   * @note  See loadedImgCount variable
   */
  function onLoadedImg() {
    // Increment loadedImgCount by 1
    setLoadedImgCount((prevValue) => prevValue + 1);
  }

  /**
   * @brief Callback function that handles viewing of next image
   * @note  We change the image to the following image only in case it is not the last image.
   * @note  The state variable "isImgFS_Loaded" is used to clear currently viewed
   *        image before rendering the next one.
   */
  function onNextBtn() {
    if (objectToViewFS.id < props.items.length-1) {
      ViewNextImg_FS(objectToViewFS.id);
      setIsImgFS_Loaded(false);
    }
  }

  /**
   * @brief Callback function that handles viewing of previous image
   * @note  We change the image to the previous image only in case it is not the first image.
   * @note  The state variable "isImgFS_Loaded" is used to clear currently viewed
   *        image before rendering the previous one.
   */
  function onPrevBtn() {
    if (objectToViewFS.id > 0) {
      ViewPrevImg_FS(objectToViewFS.id);
      setIsImgFS_Loaded(false);
    }
  }

  /**
   * @brief Callback function that handles closing of full-screen mode
   */
  function onCloseBtn() {
    setIsImgFS_Loaded(false);
    setIsImgFS(false);
  }

  // Settings related to the Masonry component
  const Columns = {
    default: 4,
    1200: 3,
    1000: 2,
    200: 1,
  };

  return (
    <div className="gallery">
      <ImgFullScreen
        isShown={isImgFS}
        imgSource={objectToViewFS.imgSource}
        previewSource={objectToViewFS.previewSource}
        onCloseBtn={onCloseBtn}
        onNextBtn={onNextBtn}
        onPrevBtn={onPrevBtn}
        onImgLoaded={setIsImgFS_Loaded.bind(this,true)}
        imgTitle={objectToViewFS.title}
        imgDate={objectToViewFS.date}
        imgLocation={objectToViewFS.location}
        isLoaded={isImgFS_Loaded}
      />
      <WaitingScreen isShown={!isGalleryLoaded} />
      <Masonry
        breakpointCols={Columns}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {props.items.map((element) => (
          <ImgGalleryItem
            key={element.id}
            id={element.id}
            isImgShown={isGalleryLoaded}
            imgSource={element.previewSource}
            imgTitle={element.title}
            onClick={viewImg_FS.bind(this, element)}
            onLoad={onLoadedImg}
          />
        ))}
      </Masonry>
    </div>
  );
}

export default Gallery;
