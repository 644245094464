import { useState } from "react";
import "./dateTimeCountdown.css";

/**
 * 
 * @param {*} props 
 * @returns 
 */
function DateTimeCountdown(props) {

    const [remainingDays, setRemainingDays] = useState();
    const [remainingHours, setRemainingHours] = useState();
    const [remainingMinutes, setRemainingMinutes] = useState();
    const [remainingSeconds, setRemainingSeconds] = useState();

    // Set the date we're counting down to
    var countDownDate = new Date(props.date).getTime();

    // Update the count down every 1 second
    var x = setInterval(function () {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Display the result in the element with id="countDown"
        setRemainingDays(days)
        setRemainingHours(hours)
        setRemainingMinutes(minutes)
        setRemainingSeconds(seconds)
        props.onLoad(true);

        // If the count down is finished, write some text
        if (distance < 0) {
            clearInterval(x);
        }
    }, 1000);
    return (
        <div className="countDown_div">
            <h1>Odpočet do svatby Bouráků</h1>
            <div className="countDownContainer">
                <div className="countDownElement">
                    <span>
                        <h2>Dny</h2>
                        <h3 id="countDown_days">{remainingDays}</h3>
                    </span>
                </div>
                <div className="countDownElement">
                    <span>
                        <h2>Hodiny</h2>
                        <h3 id="countDown_hours">{remainingHours}</h3>
                    </span>
                </div>
                <div className="countDownElement">
                    <span>
                        <h2>Minuty</h2>
                        <h3 id="countDown_mins">{remainingMinutes}</h3>
                    </span>

                </div>
                <div className="countDownElement">
                    <span>
                        <h2>Sekundy</h2>
                        <h3 id="countDown_seconds">{remainingSeconds}</h3>
                    </span>
                </div>
            </div>
        </div>

    );
}
export default DateTimeCountdown;