import React from 'react'
import "./spotifyPlaylist.css";


/**
 * @brief Renders Spotify playlist iframe
 *
 * Parameters
 * @param props.src source that leads to desired playlist
 * @param props.width playlist width
 * @param props.height playlist height
 */
function SpotifyPlaylist(props) {
  return (
    <iframe src={props.src}
      title="Title"
      className="spotifyPlaylist"
      width={props.width}
      height={props.height}
      allowFullScreen=""
      aria-hidden="false"
      loading="lazy"
      tabIndex="0">
    </iframe>
  )
}

export default SpotifyPlaylist;