import CloseIcon from "@mui/icons-material/Close";
import "./familyMemberFullScreen.css";

/**
 * @brief Renders full screen image component
 *
 * Flags
 * @param porps.isShown Determines if the ImgFullScreen is visible or not (if we want to show the full screen mode)
 * @param porps.isLoaded Shows if the full-screen image is already loaded or not
 *
 * Pointers to callback functions
 * @param props.onCloseBtn handles CloseIcon onClick event
 * @param props.onImgLoaded handles OnLoad image event
 */
function FamilyMemberFullScreen(props) {
  return (
    <div>
      <div className={props.isShown ? "background darkened" : "background"}>
      </div>

      <div className="popup">
        <div className={props.isShown ? "imgFrame shown" : "imgFrame"}>
          <div className={props.isLoaded ? "previewImg" : "previewImg shown"}>
            <img src={props.previewSource} alt={props.imgTitle} />
          </div>
          <div className={props.isLoaded ? "sourceImg shown" : "sourceImg"}>
            <img src={props.imgSource} onLoad={props.onImgLoaded} alt={props.imgTitle} />
          </div>
          <CloseIcon className="close" onClick={props.onCloseBtn} />
          <div className="info_div">
            <h3 className="info">{props.imgTitle}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FamilyMemberFullScreen;
