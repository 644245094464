import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./contactUs.css"
import { useNavigate } from "react-router-dom";
import { useState } from 'react';


export const ContactUs = () => {

  const [error, setError] = useState(null);

  const form = useRef();
  const navigate = useNavigate();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isValidName(name) {
    return name.length > 3;
  }

  function isValidMessage(msg) {
    return msg.length > 5;
  }

  function sendEmail(e) {
    e.preventDefault();

    var userName = document.getElementById("user_name").value;
    var email = document.getElementById("user_email").value;
    var message = document.getElementById("message").value;

    if (!isValidName(userName)) {
      setError('Prozraďte nám vaše jméno.');
      return 0;
    }
    else if (!isValidEmail(email)) {
      setError('Zadejte prosím funkční email');
      return 0;
    }
    else if (!isValidMessage(message)) {
      setError('Napište nám prosím něco delšího.');
      return 0;
    }


    emailjs.sendForm('gmail', 'template_4dcwex9', form.current, 'lf4vugmdFRBgYZB4V')
      .then(() => {
        navigate('/');
      }).then((result) => {
        console.log(result);
      }, (error) => {
        setError("Něco se pokazilo... Nejspíše už se nám nastřádalo moc dotazů.");
      });
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <div className="container">
          <label>Jméno</label>
          <input type="text" name="user_name" id="user_name" placeholder='Pepa Novák' />
          <label>Email</label>
          <input type="email" name="user_email" id="user_email" placeholder='PepaNovak@gmail.com' />
          <label>Zpráva</label>
          <textarea name="message" id="message" placeholder='Ahoj
chci se jen zeptat... Budete mít na rautu i Lasagně 🧐?
S pozdravem, 
Pepa'/>
          <input type="submit" value="Odeslat" />
        </div>
      </form>
      {error && <h2 className='errorMessage'>{error}</h2>}
    </>
  );
};

export default ContactUs;