import "./waitingScreen.css";

/**
 * @brief Renders waiting screen component.
 * @param props.isShown Determines whether the component is shown or hidden
 */
function WaitingScreen(props) {
  return (
    <div className={props.isShown ? "loaderContainer shown" : "loaderContainer"}>
      <div className={props.isShown ? "loader" : ""}></div>
    </div>
  );
}

export default WaitingScreen;
