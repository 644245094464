import { useEffect } from "react";
import { useState } from "react";
import FamilyMemberCard from "./familyMemberCard";
import FamilyMemberFullScreen from "./familyMemberFullScreen";

function FamilyMembers(props) {
  // State variable used to check if all images are already loaded.
  const [isGalleryLoaded, setIsGalleryLoaded] = useState(false);

  // State variable that holds value of actually loaded images.
  // Note: Used as a trigger "useEffect()" hook.  that is triggered when loadedImgCount changes.
  const [loadedImgCount, setLoadedImgCount] = useState(0);


  // State variable used to set full screen mode
  const [isImgFS, setIsImgFS] = useState(false);

  // State variable showing if we already loaded all images (loadedImgCount is equal to count of loaded images)
  const [isImgFS_Loaded, setIsImgFS_Loaded] = useState(false);

  // State variable that holds Json object of currently viewed image in ull screen
  const [objectToViewFS, setObjectToViewFS] = useState({});
  const [indexOfObjectToViewFS, setIndexOfObjectToViewFS] = useState();
  /**
   * @brief Check if we already loaded all images.
   * @note  Re-run only if the loadedImgCount changes
   */
  useEffect(() => {
    // Show gallery when all images are loaded
    // Note: Does not work perfectly, because some items have more than one image.
    if (loadedImgCount >= props.items.length - 1) {
      props.setGaleryLoaded(true);
      setIsGalleryLoaded(true);

    }
  }, [loadedImgCount, props.items.length, props]); // Dependency "props.items.length" and "props" is here only tu shush warning.

  /**
   * @brief Should be called when a single image is loaded. We increase our conter of loaded images
   * @note  See loadedImgCount variable
   */
  function onLoadedImg() {
    // Increment loadedImgCount by 1
    setLoadedImgCount((prevValue) => prevValue + 1);
  }

  /**
   * @brief Callback function that handles closing of full-screen mode
   */
  function onCloseBtn() {
    setIsImgFS_Loaded(false);
    setIsImgFS(false);
  }

  /**
   * @brief Change state variables to view image in full screen.
   * @param item Json object that contains info about the image to view.
   */
  function viewImg_FS(item, index) {
    console.log(index)
    setObjectToViewFS(item);
    setIndexOfObjectToViewFS(index)
    setIsImgFS(true);
  }

  let index = 0
  let imgSource = ""
  let previewSource = ""
  let msgText = ""

  if (indexOfObjectToViewFS !== undefined) {
    index = indexOfObjectToViewFS
  }

  if (objectToViewFS.imgSource !== undefined) {
    imgSource = objectToViewFS.imgSource[index]
  }

  if (objectToViewFS.previewSource !== undefined) {
    previewSource = objectToViewFS.previewSource[index]
  }

  if (objectToViewFS.imgDescription !== undefined) {
    msgText = objectToViewFS.imgDescription[index]
  }

  return (
    <div className="familyMembers">
      <FamilyMemberFullScreen
        isShown={isImgFS}
        imgSource={imgSource}
        previewSource={previewSource}
        onCloseBtn={onCloseBtn}
        onImgLoaded={setIsImgFS_Loaded.bind(this, true)}
        imgTitle={msgText}
        isLoaded={isImgFS_Loaded}
      />
      {props.items.map((element) => (
        <FamilyMemberCard
          key={element.id}
          id={element.id}
          isImgShown={isGalleryLoaded}
          previewSource={element.previewSource}
          familyMemberName={element.name}
          msgText={element.description}
          onLoad={onLoadedImg}
          onClick={viewImg_FS}
          imgSource={element.previewSource}       // imgSource is only passed to the "FamilyMemberFullScreen" object
          imgDescription={element.imgDescription} // imgDescription is only passed to the "FamilyMemberFullScreen" object
        />
      ))}
    </div>
  );
}

export default FamilyMembers;