import React, { useEffect, useState } from "react";

import Gallery from "../components/PhotoGallery/Gallery.js";

/**
 * @brief Page that renders our gallery of pre-wedding images
 */
function Photos() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedPhotos, setLoadedPhotos] = useState([]);

  // Fetch Json data only once
  useEffect(() => {
    fetch(
      "https://wedding-dd47b-default-rtdb.europe-west1.firebasedatabase.app/preWeddingPhotos.json"
    )
      .then((response) => {
        // The json(..) function return Promise, so we can return this function
        return response.json();
      })
      .then((data) => {
        const preWeddingPhotos = [];
        for (const key in data) {
          const preWeddingPhoto = {
            id: key,
            ...data[key],
          };
          preWeddingPhotos.push(preWeddingPhoto);
        }
        setIsLoading(false);

        setLoadedPhotos(preWeddingPhotos);
      });
  }, [isLoading]); // <- add empty brackets here to run this section only when rendered for the first time.

  // Render gallery when our data are already loaded
  if (!isLoading) {
    return <Gallery items={loadedPhotos} />;
  }
}

export default Photos;
