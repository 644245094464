import ContactUs from "../components/ContactUs";

function Contact() {
  return (
    <div>
      <p>Jestli se Nás chcete na něco zeptat, budeme rádi když napíšete 😊.</p>
      <ContactUs></ContactUs>
    </div>
  );
}
export default Contact;
