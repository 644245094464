import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./imgFullScreen.css";

/**
 * @brief Renders full screen image component
 *
 * Info related to images
 * @param props.id Identificator of currently viewed image
 * @param props.imgSource Source of currently viewed image
 * @param props.previewSource Source of preview of the viewed image
 * @param props.imgTitle Title of image to be shown
 * @param props.imgDate Date of the image to be shown
 * @param props.imgLocation Location of the image to be shown
 *
 * Flags
 * @param porps.isShown Determines if the ImgFullScreen is visible or not (if we want to show the full screen mode)
 * @param porps.isLoaded Shows if the full-screen image is already loaded or not
 *
 * Pointers to callback functions
 * @param props.onCloseBtn handles CloseIcon onClick event
 * @param props.onNextBtn handles ArrowForwardIcon onClick event
 * @param props.onPrevBtn handles ArrowBackIcon onClick event
 * @param props.onImgLoaded handles OnLoad image event
 */
function ImgFullScreen(props) {
  return (
    <>
      <div className={props.isShown ? "backgroundImgFS darkened" : "backgroundImgFS"}>
      </div>
      <div className="popupImgFS">
        <div className={props.isShown ? "imgFrame shown" : "imgFrame"}>
          <div className={props.isLoaded ? "previewImg" : "previewImg shown"}>
            <img src={props.previewSource} alt={props.imgTitle} />
          </div>

          <div className={props.isLoaded ? "sourceImg shown" : "sourceImg"}>
            <img src={props.imgSource} onLoad={props.onImgLoaded} alt={props.imgTitle} />
          </div>
          <CloseIcon className="close" onClick={props.onCloseBtn} />
          <ArrowBackIcon className="back" onClick={props.onPrevBtn} />
          <ArrowForwardIcon className="next" onClick={props.onNextBtn} />
          <div className="info_div">
            <h3 className="info">{props.imgTitle}</h3>
            <p className="info">{props.imgLocation} ({props.imgDate})</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImgFullScreen;
